import React, {Fragment} from 'react'

const About = () => {
  return (
    <Fragment>
      <h1>This is About Page</h1>
      <p>Github User search</p>
      <p>version: 1.0..0</p>
      
    </Fragment>
  )
}

export default About
